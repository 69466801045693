@import "../../index.scss";

// header 
header {
    background: $primary;
    padding-top: 10px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;

    p {
        color: $secondary;
        margin-bottom: 10px;
        @include itemPosition(center, flex-end);
        gap: 10px;

        i {
            font-size: 20px;
        }
    }

    .header {
        background: $gradient;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);

        img.logo {
            width: 100px;
        }

        .navbar-nav {
            gap: 60px;

            .nav-link {
                font-weight: 400;
                color: $primary;

                &.active {
                    color: $light;
                }

                &:hover {
                    color: $light;
                }
            }
        }

        .dropdown {
            ul {
                padding: 0;
                border-radius: 5px;
                overflow: hidden;

                li {
                    a {
                        font-size: 14px;

                        &.active {
                            background-color: $primary;
                            color: $secondary;
                        }

                        &:active {
                            background-color: $supporting;
                            color: $secondary;
                        }
                    }
                }
            }
        }
    }
}

section.review {
    padding: 70px 0;

    .reviewBox {
        background: $gradient;
        padding: 15px;
        color: $light;
        text-align: center;
        @include itemPosition(center, center);
        padding: 60px 20px;

        p {
            font-family: "Play", sans-serif;
            font-weight: 400;
            color: $grey;
            max-width: 685px;
            margin: 0 auto 15px;
        }

        h3 {
            font-family: 'Poppins', 'Roboto', 'Oxygen';
            font-size: 20px;
            margin-bottom: 0;
        }
    }
}

section.form {
    background-color: $supporting;
    padding: 15px;
    margin-top: 60px;

    .inputField {
        label {
            font-weight: 600;
        }

        input,
        textarea {
            border: none;
            border-radius: 0;
            margin-top: 10px;

            &:focus {
                box-shadow: none;
            }
        }

        input {
            height: 50px;
        }
    }
}

section.contact {
    padding: 70px 0;
}

section.ourProcess {
    .content {
        position: relative;
        margin-top: 50px;
        background-color: $grey;
        padding: 15px;

        h3 {
            max-width: 50%;
        }

        .icon {
            width: 80px;
            height: 80px;
            @include itemPosition(center, center);
            background-color: $primary;
            position: absolute;
            right: 15px;
            top: -40px;
            transition: all 0.5s;

            i {
                font-size: 35px;
            }
        }

        &:hover {
            .icon {
                transform: rotate(10deg);
            }
        }
    }
}

//footer

footer {
    padding: 60px 0 0;
    background: $gradient;
    color: $light;

    img {
        width: 150px;
    }

    p {
        font-size: 14px;
        max-width: 350px;

    }

    ul {
        list-style: none;
        padding: 0;

        li {
            margin: 0 0 15px 0;

            i {
                margin-top: 4px;
            }

            a {
                color: $light;
                font-weight: 400;

                &:hover {
                    color: $light;

                }
            }

            .contact {
                @include itemPosition(flex-start, flex-start);
                gap: 10px;
                font-weight: 400;

            }


        }
    }

    .content {
        text-align: center;
        max-width: 300px;
        margin: auto;
        padding: 0 0 25px 0;

        p {
            max-width: 250px;
            margin: 25px auto;
        }

        form {
            position: relative;

            input {
                border-radius: 0;
                background-color: #ffffff;
                color: $primary;
                border: none;
                height: 45px;
                z-index: 2;
                width: calc(100% - 60px);
                position: relative;
                padding: 0 15px;

                &::placeholder {
                    color: rgba($color: $secondary, $alpha: 0.8);
                }

                &:focus {
                    box-shadow: none;
                    background-color: #ffffff;
                    color: $secondary;
                }
            }

            .btn {
                border-radius: 0;
                height: 45px;
                background-color: $primary;
                color: $secondary;
                position: absolute;
                right: 0;
                top: 0;
                width: 60px;
                text-align: end;
                padding: 0px 20px;
                border: none;
            }
        }

        .social {
            margin-top: 20px;
            @include itemPosition(center, center);
            gap: 20px;
            padding: 5px 0;

            i {
                font-size: 20px;
                color: $light;
            }

        }

    }

    .copyright {
        background-color: $primary;

        .container {
            @include itemPosition(center, space-between);
            gap: 15px;
            text-align: end;
            color: $secondary;

            a {
                display: inline-block;
                margin: 0 10px;
                text-align: end;
                font-size: 14px;
                font-weight: 500;
                color: $secondary;
            }

        }
    }

}

@media only screen and (max-width: 1200px) {

    // header 
    header {

        .header {
            img.logo {
                width: 120px;
            }

            .navbar-nav {
                gap: 30px;
            }
        }
    }

}

@media only screen and (max-width: 768px) {

    // header 
    header {

        .header {
            img.logo {
                width: 90px;
            }

            .navbar-nav {
                gap: 20px;
            }
        }
    }
}

@media only screen and (max-width: 576px) {

    //footer
    footer {
        p {
            margin: auto;
        }

        ul {
            li {
                .contact {
                    @include itemPosition(flex-start, center);

                    &.address {
                        max-width: 283px;
                        margin: auto;
                    }
                }
            }
        }

        .copyright {
            .container {
                @include itemPosition(center, center);
                padding: 10px 0;
                flex-wrap: wrap;
                gap: 0px;
                text-align: center;

                a {
                    margin: 0 10px;
                }

            }
        }
    }
}